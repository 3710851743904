<template>
  <div class="wrapper-listado noticias">
    <div class="header-grid row-between">
      <h2>Noticias</h2>

      <div class="buscador row-end">
        <range-selector v-model="dates"></range-selector>

        <button @click="setData" class="boton-azul">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>

    <div class="contenedor-listados">
      <section class="listado-grid row-between fww">
        <article
          :key="key"
          v-for="(el, key) in data"
          :style="`background:#444 url(${el.header_img.url}) center/cover no-repeat;`"
          class="big"
        >
          <router-link
            :to="'/es/comunicacion/noticias/' + el.slug"
            class="enlace-cover"
          ></router-link>
          <div class="contenedor-texto">
            <h2>{{ el.title }}</h2>
            <h3 class="fecha">{{ el.custom_date | date }}</h3>
          </div>
        </article>
      </section>
    </div>

    <nav class="paginador" v-if="pages > 1">
      <a v-if="page > 1" @click="prev" class="prev" data="prev"
        ><i class="fa fa-caret-down" aria-hidden="true"></i
      ></a>
      <template v-for="(n, key) in pages">
        <a
          @click="setPage(n)"
          data="1"
          :class="n == page ? 'activo' : ''"
          :key="key"
          v-if="n == page - 1 || n == page + 1 || page == n"
          >{{ n }}</a
        >
      </template>
      <a v-if="page < pages" @click="next" class="next" data="next"
        ><i class="fa fa-caret-down" aria-hidden="true"></i
      ></a>
    </nav>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import moment from "moment";

export default {
  name: "noticias",
  mounted() {
    this.setData();
  },
  data: () => ({
    data: "",
    perpage: 8,
    page: 1,
    total: 0,
    dates: "",
  }),
  methods: {
    ...mapActions(["requestEntries"]),
    setData() {
      let data = {
        length: this.perpage,
        page: this.page,
        filters: [
          {
            name: "parents",
            search: 0,
          },
        ],
      };

      if (this.dates.start) {
        data.filters.push({
          name: "created_at",
          search: `${moment(this.dates.start).format(
            "YYYY/MM/DD"
          )}T00:00%datetime-local%${moment(this.dates.end).format(
            "YYYY/MM/DD"
          )}T23:59`,
        });
      }

      this.requestEntries(data).then((r) => {
        this.data = r.data;
        this.total = r.recordsFiltered;
      });
    },
    next() {
      if (this.page < this.pages) {
        this.page++;
        this.setData();
      }
    },
    prev() {
      if (this.page > 1) {
        this.page--;
        this.setData();
      }
    },
    setPage(n) {
      this.page = n;
      this.setData();
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.perpage);
    },
  },
};
</script>
